<template>
  <RevToast
    :close-alternative-text="toastInfo.closeAlternativeText"
    :duration="toastInfo.duration"
    :icon="toastInfo.icon"
    :name="toastInfo.name"
    :opened="toastInfo.isOpened"
    :title="toastInfo.title"
    :to="to"
    :variant="toastInfo.type"
    @close="closeToast"
  >
    {{ toastInfo.content }}
  </RevToast>
</template>

<script setup lang="ts">
import RevToast from '@backmarket/design-system/components/Toast/Toast.vue'

import { useTheToast } from '../composables/useTheToast'

defineProps<{ to?: string }>()

const { closeToast, toastInfo } = useTheToast()
</script>
