export default {
  closeToast: {
    id: 'wrapper_toast_close_text',
    defaultMessage: 'Close the toast',
  },
  defaultToastErrorTitle: {
    id: 'wrapper_default_toast_error_title',
    defaultMessage: 'Oops!',
  },
  defaultToastErrorContent: {
    id: 'wrapper_default_toast_error_content',
    defaultMessage: 'Sorry, something went wrong!',
  },
  defaultToastSuccessTitle: {
    id: 'wrapper_default_toast_success_title',
    defaultMessage: 'Well done!',
  },
  defaultToastSuccessContent: {
    id: 'wrapper_default_toast_success_content',
    defaultMessage: 'You are good to go!',
  },
} as const
